import React from 'react';

function App() {
	return (
		<main className='p-3 font-sans leading-tight font-extralight tracking-tighter text-white md:text-[3vw] sm:text-[8vw] p:text-[6vw]'>
			<p>Scapin Tobias</p>
			<p>Freelance Designer</p>
			<p className='py-16'>
				<p>+39 340 21 00 166</p>
				<a href='mailto:info@scapintobias.xyz?subject=New%20opportunity'>
					info@scapintobias.xyz
				</a>
			</p>

			<p>
				<a
					href='https://www.behance.net/scapintobias'
					target='_blank'
					rel='noopener noreferrer'
				>
					Portfolio
				</a>
			</p>
			<p className='pt-16'>
				<a
					href='https://www.linkedin.com/in/scapintobias'
					target='_blank'
					rel='noopener noreferrer'
				>
					Linkedin
				</a>
			</p>
		</main>
	);
}

export default App;
